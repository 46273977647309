import { useEffect, useState } from "react";
import WheelComponent from "react-wheel-of-prizes";
import Swal from "sweetalert2";
import PopupModal from "./PopupModal"; 

function Wheel() {
  const segColors = ["#EE4040", "#F0CF50", "#815CD1", "#3DA5E0", "#34A24F"];
  const onFinished = (winner) => {
    console.log(winner);
    Swal.fire({
      title: winner,
      icon: "success",
    });
  };

  const [segments, setSegments] = useState(["YES", "NO", "YES", "NO", "YES", "NO"]);
  const [reset, setReset] = useState(true);
  const [inp, setInp] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [spinCount, setSpinCount] = useState(0);

  const removeSegment = (segment) => {
    setSegments(segments.filter((ele, i) => segment !== i));
    setReset(!reset);
  };

  const addSegment = (e) => {
    e.preventDefault();
    if (inp !== "") {
      setSegments([...segments, inp]);
      setInp("");
      setReset(!reset);
    }
  };

  const saveSegments = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleModalYesClick = () => {
    console.log("Segments saved:", segments);
  };

  const handleSpinClick = () => {
    setSpinCount((prevCount) => {
      const newCount = prevCount + 1;
      if (newCount === 3) {
        setIsModalOpen(true);
      }
      console.log("Number of Spins:", newCount);
      return newCount;
    });
  };

  useEffect(() => {
    if (reset) {
      setReset(!reset);
    }
  }, [reset]);

  return (
    <>
      <div className="text-center">
        <h3 className="h2 fw-bold mb-3 mt-4">Spin The Wheel</h3>
      </div>

      <div className="d-flex align-items-center flex-column flex-md-row">
        <div className="container">
          <form>
            <div className="Input">
              <input
                type="text"
                value={inp}
                placeholder="Type here..."
                onChange={(e) => setInp(e.target.value)}
              />
              <button type="submit" onClick={(e) => addSegment(e)}>
                ADD
              </button>
              <button type="button" onClick={saveSegments}>
                SAVE
              </button>
            </div>
          </form>
          <div className="List">
            {segments.map((segment, i) => {
              return (
                <div key={i} className="ListItem">
                  <p>{segment}</p>
                  <button className="rm-btn" onClick={() => removeSegment(i)}>
                    Remove
                  </button>
                </div>
              );
            })}
          </div>
        </div>

        <div className="Wheel">
          {reset || (
            <WheelComponent
              segments={segments}
              segColors={segColors}
              onFinished={(winner) => {
                onFinished(winner);
                handleSpinClick();
              }}
              primaryColor="black"
              contrastColor="white"
              buttonText="Spin"
              isOnlyOnce={false}
              size={290}
              upDuration={500}
              downDuration={600}
              fontFamily="Arial"
            />
          )}
        </div>
      </div>

      <div className="text-center mt-4">
        <p>Number of Spins: {spinCount}</p>
      </div>

      {isModalOpen && (
        <PopupModal
          onClose={handleModalClose}
          showEmailInput={false} 
          onSignUp={() => {
            setIsModalOpen(false);
            console.log("Segments saved:", segments);
          }}
        />
      )}
    </>
  );
}

export default Wheel;
