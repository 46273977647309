import React, { useState } from "react";
import "./PopupModal.css";
import elorcaLogo from './elorca.ico'; 

const PopupModal = ({ onClose, showEmailInput, onSignUp }) => {
  const [popupContent, setPopupContent] = useState({
    title: "Wanna Save The Results  !!",
    description: "Experience the full potential! Sign up now for exclusive features, handcrafted to amplify your journey with us. Don't settle for less."
  });

  const [hideInputs, setHideInputs] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [isEmailInputVisible, setIsEmailInputVisible] = useState(false);

  const handleSignUpClick = () => {
    if (!email) {
      setError("Email is required");
      return;
    }
    setPopupContent({
      title: "YYYaaayyyy !!!",
      description: "Thank you for signing up! Enjoy exclusive features and more."
    });
    setHideInputs(true);
    setTimeout(() => {
      window.location.replace("https://www.elorca.com/login/");
    }, 500); 
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setError("");
  };

  const handleYesClick = () => {
    setIsEmailInputVisible(true);
  };

  return (
    <div className="popup-container">
      <div className="popup-content">
        <img src={elorcaLogo} alt="Logo" className="popup-logo" />
        <div className="popup-text">
          <p className="popup-title">{popupContent.title}</p>
          <p className="popup-description">{popupContent.description}</p>
          <p className="popup-desc">Unlimited Moves</p>
          <p className="popup-desc" style={{ marginTop: "-20px" }}>More Games</p>
        </div>
        {isEmailInputVisible && !hideInputs && (
          <div className="popup-input">
            <input
              type="text"
              className="form-control"
              placeholder="Enter email address"
              aria-label="Recipient's username"
              aria-describedby="button-addon2"
              style={{ borderRadius: "20px 0 0 20px" }}
              value={email}
              onChange={handleEmailChange}
            />
            <button
              className="btn btn-outline-secondary"
              type="button"
              id="button-addon2"
              style={{ borderRadius: "0 20px 20px 0", backgroundColor: "rgb(252, 169, 3)", color: "black" }}
              onClick={handleSignUpClick}
            >
              Sign Up
            </button>
          </div>
        )}
        {error && <p className="error-text" style={{ color: "red" }}>{error}</p>}
        {!isEmailInputVisible && (
          <div className="popup-buttons">
            <button className="popup-button" onClick={handleYesClick}>Yes</button>
            <button className="popup-button" onClick={onClose}>No, I'm good with the basics.</button>
          </div>
        )}
        <button
          className="close-button"
          onClick={onClose}
        >
          X
        </button>
      </div>
    </div>
  );
};

export default PopupModal;
